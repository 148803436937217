import React from "react"

const Timeline = props => (
<section>
<div className="item">
    <div className="elements">
        <div className={props.circle}></div>
        <div className="horizontal-line"></div>
        <div className="small-circle"></div>
        <div className="timeline-text">
            <h2 className="h6 fw-bold">{props.title}</h2>
            <p className="text-dark fw-bold mb-2">{props.date} | {props.location}</p>
            <p>{props.description}</p>
        </div>
    </div>
    <div className="vertical-line"></div>
</div>
</section>
)
export default Timeline;